<style lang="scss" scoped>
@import "@assets/styles/variables.scss"; //公共样式变量
</style>

<template>
  <!-- 权限管理 -->
  <section class="page-container">
    <div class="table-box">
      <div class="table-container" id="tableContainer">
        <!-- 内页工具栏 -->
        <div class="tool-bar">
          <div class="tool-left">
            <el-button
              size="small"
              type="primary"
              v-if="smallRoleObj.add"
              icon="el-icon-plus"
              @click="
                $router.push({
                  name: 'ROLE_ADD',
                  params: { pid: 0 },
                })
              "
            >
              添加顶级
            </el-button>
          </div>
          <div class="tool-right">
            <el-tooltip effect="dark" content="刷新">
              <el-button
                size="small"
                icon="el-icon-refresh-right"
                @click="handleRefresh"
              />
            </el-tooltip>
            <el-tooltip effect="dark" content="全屏">
              <el-button size="small" @click="handleFullScreen">
                <span class="iconfont">
                  {{ (isFull && "&#xe641;") || "&#xe8fa;" }}
                </span>
              </el-button>
            </el-tooltip>
          </div>
        </div>
        <div class="table-data no-page" ref="tableContainer">
          <!-- 内页表格数据 -->
          <el-table
            v-loading="tableLoading"
            ref="tableBox"
            border
            style="width: 100%"
            row-key="id"
            default-expand-all
            :max-height="tableHeight"
            :data="tableData"
            :tree-props="treeProps"
          >
            <!-- <el-table-column type="index" width="50" align="center" label="序号" /> -->

            <!-- 改变箭头所在列，把想要设置展开行的列的前面的所有没有type的设置一个type=""-->
            <el-table-column
              type=""
              prop="id"
              width="50"
              align="center"
              label="ID"
            />

            <el-table-column
              prop="name"
              label="权限名称"
              min-width="180"
              align="left"
            />
            <el-table-column
              prop="system_name_name"
              label="所属系统"
              min-width="180"
              align="left"
            />
            <el-table-column
              prop="route_url"
              label="后端路由地址"
              width="220"
              align="center"
            />
            <el-table-column
              prop="front_icon"
              label="图标"
              width="220"
              align="center"
            />
            <el-table-column
              prop="front_route_url"
              label="前端路由地址"
              width="220"
              align="center"
            />
            <el-table-column
              prop="front_route_name"
              label="前端路由名称"
              width="220"
              align="center"
            />
            <el-table-column label="权限类型" width="100" align="center">
              <template slot-scope="scope">
                <el-tag
                  :is_menu="(scope.row.is_menu == '1' && 'success') || ''"
                  v-if="scope.row.is_menu"
                >
                  {{ (scope.row.is_menu == "1" && "菜单") || "按钮" }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="sort"
              label="排序"
              width="80"
              align="center"
            />
            <el-table-column
              label="操作"
              width="240"
              align="center"
              fixed="right"
            >
              <template slot-scope="scope">
                <el-button
                  class="color-primary"
                  type="text"
                  v-if="smallRoleObj.add"
                  icon="el-icon-plus"
                  @click="
                    $router.push({
                      name: 'ROLE_ADD',
                      params: { pid: scope.row.id },
                    })
                  "
                >
                  添加子级
                </el-button>
                <el-button
                  class="color-primary"
                  type="text"
                  v-if="smallRoleObj.update"
                  icon="el-icon-edit"
                  @click="
                    $router.push({
                      name: 'ROLE_EDIT',
                      params: { id: scope.row.id },
                    })
                  "
                >
                  编辑
                </el-button>
                <el-button
                  class="color-danger"
                  type="text"
                  v-if="smallRoleObj.delete"
                  icon="el-icon-delete"
                  @click="handleDelete(scope.row.id)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { roleList, roleUpdate, roleAdd, roleDelete } from "@api/authority";
export default {
  name: "roleList",
  inject: ["reload"],
  data() {
    return {
      tableLoading: false,
      tableHeight: 0, //表格最大高度
      searchForm: {}, //搜索表单
      isFull: false, //全屏开启
      tableData: [], //表格数据
      treeProps: { children: "_child", hasChildren: "hasChildren" }, //树型表格配置项
      id: null, //修改部门id
      formDialogVisible: false, //修改/添加表单弹窗显示
      smallRoleObj: {},
      formData: {
        type: 1,
        sort: 200,
      }, //表单存储数据
      rules: {
        name: [{ required: true, message: "请输入权限名称", trigger: "blur" }],
        type: [
          { required: true, message: "请输入选择权限类型", trigger: "change" },
        ],
        routerLink: [
          { required: true, message: "请输入路由地址", trigger: "blur" },
        ],
        routerName: [
          { required: true, message: "请输入路由名称", trigger: "blur" },
        ],
        modularName: [
          { required: true, message: "请输入模块名称", trigger: "blur" },
        ],
        controllerName: [
          { required: true, message: "请输入控制器名称", trigger: "blur" },
        ],
        operationName: [
          { required: true, message: "请输入操作名称", trigger: "blur" },
        ],
        backRouter: [
          { required: true, message: "请输入后端路由名称", trigger: "blur" },
        ],
        sort: [
          { required: true, message: "请选择部门排序", trigger: "change" },
        ],
      }, //表单校验规则
    };
  },
  computed: {
    ...mapState("system", ["menuListSearch", "menuTreeList"]),
  },
  created() {
    // 获取初始化数据
    this.getData();
    /**
     * 页面dom元素加载完后调用，计算表格最大高度
     */
    this.$nextTick(function () {
      this.initTableHeight();
    });
  },
  async mounted() {
    window.addEventListener("resize", () => {
      // 窗口尺寸改变时触发事件
      if (!this.$screenfull.isFullscreen) {
        this.isFull = false;
      }
      this.initTableHeight();
    });
    this.smallRoleObj = await this.validateSmallRole();
    this.$forceUpdate();
  },
  methods: {
    ...mapActions("system", ["getMenuList"]),
    /** 获取表格数据 */
    async getData() {
      this.tableLoading = true;
      /**
       * 请求接口数据
       */
      await this.getMenuList();
      this.tableData = this.menuTreeList;
      this.tableLoading = false;
      this.$forceUpdate();
    },
    /** 查询 */
    handleSearch() {
      this.getData();
    },
    /** 刷新页面 */
    handleRefresh() {
      this.reload();
    },
    /** 初始化表格高度 */
    initTableHeight() {
      if (this.$refs["tableContainer"]) {
        this.tableHeight = this.$refs["tableContainer"].offsetHeight;
      }
    },
    /** 全屏 */
    handleFullScreen() {
      const element = document.getElementById("tableContainer");
      this.isFull = !this.isFull;
      if (this.isFull) {
        this.$screenfull.request(element);
      } else {
        this.$screenfull.exit(element);
      }
    },
    handleShowDialog(id, type = 1) {
      this.formData = {
        ...this.formData,
        pid: id,
        type: type,
      };
      console.log(this.formData);
      this.formDialogVisible = true;
    },
    /** 修改前获取详情信息 */
    async getDetail(id) {
      this.id = id;
      /**
       * 请求接口获取详情数据(权限详情)
       */
      let res = await roleUpdate({ id: this.id }, "get");
      res.data.type = Number(res.data.type);
      this.formData = {
        ...this.formData,
        ...res.data,
      };

      // 数据请求返回成功后打开弹窗
      this.formDialogVisible = true;
    },
    handleCancle() {
      this.formDialogVisible = false;
      this.formData = {
        type: 1,
        sort: 200,
      };
      this.id = null;
      // this.$refs["formData"].clearValidate();
    },
    handleSubmit() {
      this.$refs["formData"].validate(async (valid) => {
        if (valid) {
          /**
           * 请求接口，修改密码
           */
          let res = null;
          if (this.id) {
            // 存在id，执行编辑请求
            res = await roleUpdate({ ...this.formData }, "post");
          } else {
            // 不存在id，执行添加请求
            res = await roleAdd({ ...this.formData });
          }
          // 请求后，不论是否成功，调用方法关闭弹窗
          this.handleCancle();
          // 请求成功后，调用方法，更新页面数据
          this.getData();
        }
      });
    },
    /** 删除 */
    handleDelete(id) {
      /** 弹窗再次确认操作 */
      this.$confirm("此操作将永久删除该权限, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        /** 确认触发 */
        .then(async () => {
          /**
           * 请求接口删除数据
           */
          let res = await roleDelete(id);
          if (res.code == 1) {
            this.$message({
              type: "success",
              message: "权限已删除!",
            });
          }

          this.getData();
        })
        /** 取消触发 */
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除操作",
          });
        });
    },
  },
};
</script>